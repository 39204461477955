<template>
  <div class="tip">
    <div class="container">
      <div class="main">
        <p>
          屏幕中间的红框中将出现一个图像，<br />
          请在它下方的四个图像中识别并选出与其完全相同的一个。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null,
    };
  },
  methods: {},
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.$router.replace('/delay');
    }, 2000);
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 1900px) {
  .tip {
    display: table-cell;
    vertical-align: middle;
    font-family: 'SYHeiBold';
    .container {
      .main {
        text-align: center;
        padding: 265px 0;
        p {
          font-size: 32px;
          line-height: 52px;
          color: #5f9160;
        }
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .tip {
    display: table-cell;
    vertical-align: middle;
    font-family: 'SYHeiBold';
    .container {
      .main {
        text-align: center;
        padding: 345px 0;
        p {
          font-size: 42px;
          line-height: 68px;
          color: #5f9160;
        }
      }
    }
  }
}
</style>
